import activate from './activate';
import queryAll from '../query-all';

/**
 * Handles arrow keyboard logic
 * @param {Object} data  Object containing the following properties:
 *   @prop {Number} key keydown keycode
 *   @prop {HTMLElement} listbox the listbox element
 */
const keypress = (data) => {
  const isNext = ['ArrowDown', 'Down'].indexOf(data.code) !== -1;
  const activeID = data.listbox.getAttribute('aria-activedescendant');
  const selectedOption = activeID && document.getElementById(activeID);

  if (!selectedOption) {
    return;
  }

  const options = queryAll('[role="option"]', data.listbox).filter(
    (o) => o.getAttribute('aria-disabled') !== 'true',
  );
  const index = options.indexOf(selectedOption);
  const adjacentIndex = isNext ? index + 1 : index - 1;

  if (adjacentIndex !== -1 && adjacentIndex !== options.length) {
    const adjacentOption = options[adjacentIndex];
    data.listbox.setAttribute('aria-activedescendant', adjacentOption.id);

    // set active class
    activate(data.listbox);
  }
};

export default keypress;
