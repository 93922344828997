import activate from './events/activate';
import queryAll from './query-all';

const TYPE_TIME = 600;
let timer;
let keys = [];

/**
 * Handles searching the options / jumping to option based on characters typed
 */
const search = (keyPressed, listbox) => {
  const searchSelect = (matches) => {
    if (!matches.length) {
      return;
    }

    const current = listbox.querySelector('.customSelect--selected');
    const currentIndex = matches.indexOf(current);
    const nextIndex = currentIndex + 1;
    const toBeSelected = matches[nextIndex] || matches[0];

    if (toBeSelected === current) {
      return;
    }

    listbox.setAttribute('aria-activedescendant', toBeSelected.id);
    activate(listbox);
    toBeSelected.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  };

  clearTimeout(timer);

  const options = queryAll('[role="option"]', listbox).filter(
    (o) => o.getAttribute('aria-disabled') !== 'true',
  );

  keys.push(keyPressed.replace(/^(Key|Digit)/, '').toLowerCase());

  // find the FIRST option that most closely matches our keys
  // if that first one is already selected, go to NEXT option
  const stringMatch = keys.join('');
  // attempt an exact match
  const deepMatches = options.filter(
    (o) => o.dataset.label.toLowerCase().indexOf(stringMatch) === 0,
  );

  if (deepMatches.length) {
    searchSelect(deepMatches);
  } else {
    // plan b - first character match
    const firstChar = stringMatch[0];
    searchSelect(options.filter((o) => o.dataset.label.toLowerCase().indexOf(firstChar) === 0));
  }

  timer = setTimeout(() => {
    keys = [];
  }, TYPE_TIME);
};

export default search;
