import Flickity from 'flickity';

const setUpSliders = (container) => {
  const slider = container.querySelector('.postlist-categories');

  let selectedIndex = -1;
  const selectedSlide = slider.querySelector('[data-categories-selected]');

  if (selectedSlide) {
    selectedIndex = parseInt(selectedSlide.dataset.categoriesSelected, 10);
  }

  const isRightToLeft = document.documentElement.getAttribute('dir') === 'rtl';

  const nextButton = container.querySelector('[data-slider-next]');
  const prevButton = container.querySelector('[data-slider-prev]');

  const flcktyInstance = new Flickity(slider, {
    contain: true,
    groupCells: true,
    pageDots: false,
    cellAlign: 'left',
    prevNextButtons: false,
    watchCSS: true,
    rightToLeft: isRightToLeft,
    on: {
      ready: () => {
        Array.from(slider.querySelectorAll('li')).forEach((el) => {
          /* eslint-disable no-param-reassign */
          el.style.top = '0';
          el.style.bottom = '0';
          /* eslint-enable */
        });
      },
    },
  });

  const flickityIsActive = () => {
    if (!Object.prototype.hasOwnProperty.call(flcktyInstance, 'slides')) {
      return false;
    }

    if (!flcktyInstance.slides.length) {
      return false;
    }

    return true;
  };

  if (selectedIndex !== -1 && flickityIsActive()) {
    flcktyInstance.selectCell(selectedIndex, true, true);
  }

  const checkButtons = (index) => {
    if (!flickityIsActive()) {
      return;
    }

    if (index === 0) {
      prevButton.setAttribute('disabled', true);
    }

    if (index === flcktyInstance.slides.length - 1) {
      nextButton.setAttribute('disabled', true);
    }

    if (index < flcktyInstance.slides.length - 1) {
      nextButton.removeAttribute('disabled');
    }

    if (index > 0) {
      prevButton.removeAttribute('disabled');
    }
  };

  checkButtons(flcktyInstance.selectedIndex);
  flcktyInstance.on('change', checkButtons);

  container.addEventListener('click', (event) => {
    if (event.target.matches('[data-slider-next]')) {
      event.preventDefault();
      flcktyInstance.next();
    }

    if (event.target.matches('[data-slider-prev]')) {
      event.preventDefault();
      flcktyInstance.previous();
    }
  });
};

// Used for the taxonomy / terms version of the filters on the archive pages
const checkboxClickHandlder = (event, dropdowns) => {
  const { target } = event;

  if (target.matches('.checkbox-dropdown')) {
    if (target.classList.contains('is-active')) {
      target.classList.remove('is-active');
      return;
    }

    dropdowns.forEach((dropdown) => dropdown.classList.remove('is-active'));
    target.classList.add('is-active');

    return;
  }

  if (!target.closest('.checkbox-dropdownList')) {
    dropdowns.forEach((dropdown) => dropdown.classList.remove('is-active'));
  }
};

const handleCheckboxListKeyboardEvent = (event) => {
  const { code, shiftKey, target } = event;

  switch (code) {
    case 'Tab': {
      if (shiftKey) {
        // we always do something with shift+tab
        event.preventDefault();

        // go to previous list item
        if (target.previousElementSibling) {
          target.previousElementSibling.focus();
          return;
        }

        // no previous list item, go to the .checkbox-dropdown div
        const dropdown = target.parentElement.previousElementSibling;
        dropdown.classList.remove('is-active');
        dropdown.focus();
        return;
      }

      // go to next list item
      if (target.nextElementSibling) {
        event.preventDefault();
        target.nextElementSibling.focus();
        return;
      }

      // no next list item, go to the next .checkbox-dropdown div
      const nextDropdown = target.parentElement.parentElement.nextElementSibling;
      const thisDropdown = target.parentElement.previousElementSibling;
      if (nextDropdown) {
        event.preventDefault();
        thisDropdown.classList.remove('is-active');
        nextDropdown.firstElementChild.focus();
      }
      return;
    }
    case ' ':
    case 'Space':
    case 'Spacebar': {
      // select the checkbox within the list item
      event.preventDefault();
      const box = target.querySelector('input[type="checkbox"]');
      box.checked = !box.checked;
      return;
    }
    case 'Down':
    case 'ArrowDown':
      // go to the next list item, if available
      if (target.nextElementSibling) {
        event.preventDefault();
        target.nextElementSibling.focus();
      }
      return;
    case 'Up':
    case 'ArrowUp': {
      // we always do something with up arrow
      event.preventDefault();

      // go to the previous list item, if available
      if (target.previousElementSibling) {
        target.previousElementSibling.focus();
        return;
      }

      // no previous list item, go to its .checkbox-dropdown div
      const dropdown = target.parentElement.previousElementSibling;
      dropdown.classList.remove('is-active');
      dropdown.focus();
      return;
    }
    case 'Esc':
    case 'Escape': {
      const dropdown = target.parentElement.previousElementSibling;
      dropdown.classList.remove('is-active');
      dropdown.focus();
      break;
    }
    default:
      break;
  }
};

const checkboxKeyboardHandler = (event, dropdowns) => {
  const { target, code } = event;

  // we're in the wrong scope, skip!
  if (!target.closest('.checkbox-dropdownList-trigger')) {
    return;
  }

  // list events
  if (target.parentElement.matches('.checkbox-dropdownList')) {
    handleCheckboxListKeyboardEvent(event);
    return;
  }

  // .checkbox-dropdown events
  switch (code) {
    case 'Enter':
    case ' ':
    case 'Space':
    case 'Spacebar':
      event.preventDefault();
      target.classList.toggle('is-active');
      return;
    case 'Up':
    case 'ArrowUp':
      event.preventDefault();
      if (target.classList.contains('is-active')) {
        target.classList.remove('is-active');
      }
      return;
    case 'Down':
    case 'ArrowDown':
      event.preventDefault();

      if (!target.classList.contains('is-active')) {
        target.classList.add('is-active');
      }

      // focus on the first item on navigation down
      if (target.nextElementSibling.matches('.checkbox-dropdownList')) {
        target.nextElementSibling.firstElementChild.focus();
      }
      return;
    case 'Left':
    case 'ArrowLeft':
      event.preventDefault();
      target.classList.remove('is-active');

      if (target.parentElement.previousElementSibling) {
        target.parentElement.previousElementSibling.firstElementChild.focus();
      }
      return;
    case 'Right':
    case 'ArrowRight':
      event.preventDefault();
      target.classList.remove('is-active');

      if (target.parentElement.nextElementSibling) {
        target.parentElement.nextElementSibling.firstElementChild.focus();
      }
      return;
    case 'Esc':
    case 'Escape':
      dropdowns.forEach((dropdown) => dropdown.classList.remove('is-active'));
      break;
    default:
      break;
  }
};

const init = () => {
  const postCategories = Array.from(document.querySelectorAll('[data-slider]'));
  const filterDropdowns = Array.from(document.querySelectorAll('.checkbox-dropdown'));

  if (filterDropdowns.length) {
    document.addEventListener('click', (event) => checkboxClickHandlder(event, filterDropdowns));
    document.addEventListener('keydown', (event) =>
      checkboxKeyboardHandler(event, filterDropdowns),
    );
  }

  if (postCategories.length < 1) {
    return;
  }

  postCategories.forEach(setUpSliders);
};

export default init;
