import delegate from 'delegate';
import activate from './events/activate';
import keypress from './events/keys';
import open from './events/open';
import select from './events/select';
import search from './search';

const { escapeRegExp } = lodash;

const isLetterOrNum = (key) => /^(Key|Digit)[A-Z0-9]$/.test(key);

const getLabel = (el, parentSelector, labelSelector) => {
  const dataLabelId = el.getAttribute('data-label-id');
  if (dataLabelId) {
    return document.getElementById(dataLabelId);
  }

  const parent = el.closest(parentSelector);
  return parent && parent.querySelector(labelSelector);
};

const attachEvents = (listboxButton, listbox) => {
  const container = listbox.closest('.customSelect');
  const inputs = Array.from(container.querySelectorAll('.customSelect-input'));

  const restoreSelected = () => {
    const cachedSelected = listbox.getAttribute('data-cached-selected');
    listbox.setAttribute('aria-activedescendant', cachedSelected);
  };

  function onMouseUp() {
    listboxButton.focus();
    listbox.classList.remove('customSelect--listShow');
    listboxButton.setAttribute('aria-expanded', 'false');
    document.removeEventListener('mouseup', onMouseUp);
  }

  function onActivate(event) {
    event.preventDefault();

    listbox.classList.toggle('customSelect--listShow');
    const hasShowClass = listbox.classList.contains('customSelect--listShow');

    // set expanded state
    listboxButton.setAttribute('aria-expanded', hasShowClass ? 'true' : 'false');

    if (hasShowClass) {
      open(listboxButton, listbox);
    }
  }

  /**
   * attach native label click to focus control behavior
   */
  const label = getLabel(listboxButton, '.customSelect', '.customSelect-label');
  if (label) {
    label.addEventListener('mouseup', () => listboxButton.focus());
  }

  listbox.addEventListener('blur', () => {
    onMouseUp();
  });

  /**
   * listboxButton events
   */
  listboxButton.addEventListener('mousedown', (e) => e.preventDefault());
  listboxButton.addEventListener('click', (e) => e.preventDefault());
  listboxButton.addEventListener('mouseup', onActivate);
  listboxButton.addEventListener('keydown', (e) => {
    const keys = ['Enter', ' ', 'Space', 'Spacebar', 'ArrowDown', 'Down'];

    if (keys.indexOf(e.code) === -1) {
      return;
    }

    onActivate(e);
  });

  /**
   * if select is standalone form, handle form submission
   */
  listboxButton.addEventListener('change', (e) => {
    const isForm = container.nodeName === 'FORM';
    const isDownload = container.classList.contains('download-block');
    const { value } = e.detail;

    if (!isForm && !isDownload) {
      return;
    }

    const isOutdated = inputs.filter((input) => input.value !== value);

    if (isOutdated.length === 0) {
      return;
    }

    const { type } = container.dataset;

    // no-op this if it's not a nav
    if (type === 'nav') {
      // no-op this if it's not a URI
      if (value.match(/^https?:\/\//i) === null) {
        return;
      }

      window.location = value;
      return;
    }

    inputs.forEach((input) => {
      // input is an input element
      if (input.getAttribute('value')) {
        input.setAttribute('value', value);
        return;
      }

      // input is an anchor element
      if (input.getAttribute('href')) {
        input.setAttribute('href', value);
        input.setAttribute('download', value.split('/').pop());
      }
    });

    if (isForm && value) {
      container.submit();
      return;
    }

    inputs.forEach((input) => {
      if (!input.name) {
        return;
      }

      const name = input.getAttribute('name');
      let { search: str } = window.location;

      if (str.indexOf(name) === -1) {
        return;
      }

      str = str.replace(new RegExp(`[?&]${escapeRegExp(name)}=[^\\b&\\s]*`), '');

      window.location.search = str;
    });
  });

  /**
   * Listbox events
   */
  listbox.addEventListener('keydown', (e) => {
    const { code } = e;

    switch (code) {
      case 'Tab':
        // restore selected
        restoreSelected();
        break;
      case 'Up': // IE
      case 'Down': // IE
      case 'ArrowUp':
      case 'ArrowDown':
        e.preventDefault();
        keypress({ code, listbox });
        break;
      case 'Enter':
      case ' ':
      case 'Space':
      case 'Spacebar': // IE
        e.preventDefault();
        select(listboxButton, listbox);
        break;
      case 'Esc': // IE
      case 'Escape':
        // restore previously selected
        restoreSelected();
        listbox.classList.remove('customSelect--listShow');
        listboxButton.setAttribute('aria-expanded', 'false');
        listboxButton.focus();
        break;
      default:
        if (isLetterOrNum(code)) {
          search(code, listbox);
        }
    }
  });

  delegate(listbox, '[role="option"]', 'mouseup', (e) => {
    const option = e.delegateTarget;

    if (option.getAttribute('aria-disabled') === 'true') {
      setTimeout(() => listboxButton.focus());
      return;
    }

    listbox.setAttribute('aria-activedescendant', option.id);

    activate(listbox);
    select(listboxButton, listbox, true);

    document.removeEventListener('mouseup', onMouseUp);
    document.addEventListener('mouseup', onMouseUp);
  });
};

export default attachEvents;
