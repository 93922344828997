const html = document.documentElement;
const classCache = html.className;
let header;
let scrollPos = 0;

// toggle element visibility on trigger clicks
const triggerToggle = (event) => {
  const { toggle, state, self } = event.target.dataset;

  let element;

  if (toggle.indexOf('^') === 0) {
    element = event.target.parentElement;

    if (toggle !== '^parent') {
      element = element.querySelector(toggle.replace(/^\^/, ''));
    }
  } else {
    element = document.querySelector(toggle);
  }

  // no target element
  if (!element) {
    return;
  }

  event.preventDefault();

  // set class on event target
  if (self) {
    event.target.classList.toggle(self);
  }

  // toggle active class name
  element.classList.toggle('is-open');
  const isOpen = element.classList.contains('is-open');

  if (element.getAttribute('tabindex') !== null) {
    element.setAttribute('tabindex', isOpen ? '0' : '-1');
  }

  if (element.getAttribute('aria-hidden') !== null) {
    element.setAttribute('aria-hidden', isOpen ? 'false' : 'true');
  }

  if (element.getAttribute('aria-expanded') !== null) {
    element.setAttribute('aria-expanded', isOpen ? 'true' : 'false');
  }

  if (element.style.visibility !== '') {
    element.style.visibility = isOpen ? 'visible' : 'hidden';
  }

  // target has descendent with autofocus
  if (element.dataset.autofocus) {
    const focusInto = element.querySelector(element.dataset.autofocus);

    // found autofocus target
    if (focusInto) {
      setTimeout(() => focusInto.focus({ preventScroll: true }), 0);
    }
  }

  // set state on html
  if (state) {
    html.classList.toggle(state);
  }
};

// dispatch click target changes
const handleClickEvents = (event) => {
  const { target } = event;
  const { dataset } = target;

  // click event is on a toggle
  if (dataset.toggle) {
    // no state set on html
    if (html.className === classCache) {
      scrollPos = html.scrollTop;
    }

    triggerToggle(event);

    if (html.className === classCache) {
      html.scrollTop = scrollPos;
    } else {
      html.style.top = `-${scrollPos}px`;
    }
  }

  // it's on the background overlay (something is open in front)
  if (target.classList.contains('overlay')) {
    html.setAttribute('class', classCache);
  }
};

// collapse open items when escape key is pressed
const handleKeyEvents = (event) => {
  const { code } = event;

  // remove all stateful classes from the document
  if (['Esc', 'Escape'].indexOf(code) !== -1) {
    html.setAttribute('class', classCache);
  }
};

// previous scrollY cache
let previousKnown = 0;
// toggle sticky class on header
const maybeStick = (position) => {
  // do nothing
  if (position === previousKnown) {
    return;
  }

  // don't interfere with stateful changes
  if (html.className.indexOf('-open') !== -1) {
    return;
  }

  // we're at the top
  if (position === 0) {
    header.classList.remove('stick');
    return;
  }

  if (position > previousKnown) {
    // scrolling down, unstick
    header.classList.remove('stick');
  } else {
    // scrolling up, stick
    header.classList.add('stick');
  }

  previousKnown = position;
};

// scroll handler values cache
let lastKnown = 0;
let ticking = false;

const handleScrollEvents = () => {
  lastKnown = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(() => {
      maybeStick(lastKnown);
      ticking = false;
    });

    ticking = true;
  }
};

const init = () => {
  html.addEventListener('click', handleClickEvents);
  html.addEventListener('keydown', handleKeyEvents);

  header = document.querySelector('.page-header');

  if (!header) {
    return;
  }

  document.addEventListener('scroll', handleScrollEvents);
};

export default init;
