import '../styles/app.scss';
import './polyfills';

import Expose from './modules/Expose';
import popIn from './modules/pop-in';
import fluidText from './modules/fluid-text';
import languageSelector from './modules/language-selector';
import header from './modules/header';
import subcatDrops from './modules/subcategory-dropdown';
import selects from './modules/custom-select';
import searchFilters from './modules/search-filters';
import filterPosts from './modules/filter-posts';
import loadVideos from './modules/video-loaded';
import fluidIframe from './modules/fluid-iframe';
import tweetAction from './modules/tweet-action';
import categorySlider from './modules/category-slider';
import modalEmbed from './modules/modal-embed';
import sliderBlock from './modules/slider-block';
import counters from './modules/counter';
import termList from './modules/term-list';
import countdownTimer from './modules/countdownTimer';
import tabbedNav from './modules/tabbed-nav';
import browserDetector from './modules/browser-detector';
import authorBioToggle from './modules/author-bio-toggle';
import iframeButton from './modules/iframe-button';

const App = () => {
  browserDetector();
  popIn();
  languageSelector();
  header();
  subcatDrops();
  selects();
  searchFilters();
  filterPosts();
  fluidIframe();
  tweetAction();
  categorySlider();
  modalEmbed();
  sliderBlock();
  counters();
  termList();
  countdownTimer();
  tabbedNav();
  loadVideos();
  authorBioToggle();
  iframeButton();

  fluidText(document.getElementsByClassName('article-shareTitle'), 0.9);

  return {
    using: Expose(),
  };
};

/**
 * Export to `window.App.default()`.
 */
export default App;
