import objectToQueryString from './filter-utils';

const inputsToObject = (inputs) => {
  const request = {};

  inputs.forEach((input) => {
    if (!input.value || !input.name) {
      return;
    }

    if (input.nodeName === 'SELECT') {
      request[input.name] = input.value;
      return;
    }

    if (input.type === 'checkbox') {
      if (!input.checked) {
        return;
      }

      if (input.name.indexOf('[]') === -1) {
        request[input.name] = input.value;
        return;
      }

      const arrName = input.name.replace(/\[\]/, '');
      if (!Object.hasOwnProperty.call(request, arrName)) {
        request[arrName] = [];
      }

      request[arrName].push(input.value);
    }
  });

  return request;
};

const handleFilterSubmit = (event) => {
  event.preventDefault();

  const inputs = Array.from(event.target.querySelectorAll('input,select'));
  const request = inputsToObject(inputs);
  const { search } = window.location;

  if (!search) {
    window.location.search = objectToQueryString(request);
    return;
  }

  window.location.search = `${search}&${objectToQueryString(request)}`;
};

export default function searchFilters() {
  const form = document.getElementById('filter-form');

  if (!form) {
    return;
  }

  form.addEventListener('submit', handleFilterSubmit);
}
