const rndm = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charLength = characters.length;
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charLength));
  }

  return result;
};

const rndid = (len = 8) => {
  const id = rndm(len);

  if (document.getElementById(id)) {
    return rndid(len);
  }

  return id;
};

export default rndid;
