import attachEvents from './custom-select/attach-events';
import queryAll from './custom-select/query-all';
import rndid from './custom-select/rndid';
import getTextWidth from './custom-select/text-width';

/**
 * Most of the code for this was taken from
 * https://github.com/dequelabs/pattern-library/wiki/selects
 */

const textWidth = (element) => {
  const style = window.getComputedStyle(element);
  const { font } = style;
  let text = element.textContent;

  switch (style.textTransform) {
    case 'uppercase':
      text = text.toUpperCase();
      break;

    case 'lowercase':
      text = text.toLowerCase();
      break;

    default:
      break;
  }

  return getTextWidth(text, font);
};

const cache = [];

const init = (listboxButton) => {
  if (cache.indexOf(listboxButton) > -1) {
    return;
  }

  cache.push(listboxButton);

  const wrapper = listboxButton.closest('.customSelect-wrapper');
  const listbox = wrapper.querySelector('[role="listbox"]');

  if (!listbox) {
    return;
  }

  listbox.tabIndex = -1;

  // ensure pseudo value element is present
  let pseudoVal = listboxButton.querySelector('.customSelect-fieldValue');
  if (!pseudoVal) {
    pseudoVal = document.createElement('div');
    pseudoVal.classList.add('customSelect-fieldValue');
    listboxButton.appendChild(pseudoVal);
  }

  let width = 0;
  // ensure all options have an id
  queryAll('[role="option"]', listbox).forEach((o) => {
    // eslint-disable-next-line
    o.id = o.id || rndid();
    width = Math.max(width, textWidth(o));
  });

  // set the "select" width
  const buttonStyle = getComputedStyle(listboxButton);
  width += parseFloat(buttonStyle.paddingLeft);
  width += parseFloat(buttonStyle.paddingRight);
  width += parseFloat(buttonStyle.borderLeftWidth);
  width += parseFloat(buttonStyle.borderRightWidth);
  width += 10; // magic breathing room
  // eslint-disable-next-line
  listboxButton.closest('.customSelect-wrapper').style.width = `${width}px`;

  // check if there is a default selected and ensure it has the right attrs/classes
  const activeId = listbox.getAttribute('aria-activedescendant');
  const active = activeId && document.getElementById(activeId);

  if (active) {
    active.setAttribute('aria-selected', 'true');
    active.classList.add('customSelect--selected');
  }

  // attach native click-label-focus-control behavior
  attachEvents(listboxButton, listbox);
};

export default () => {
  Array.from(document.querySelectorAll('[aria-haspopup="listbox"]')).forEach(init);
};
