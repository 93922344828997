import activate from './activate';
import queryAll from '../query-all';

/**
 * Opens the listbox
 */
const open = (listboxButton, listbox) => {
  const activeDesc = listbox.getAttribute('aria-activedescendant');

  if (!activeDesc) {
    // theres no initially selected => default to the first
    const nonDisableds = queryAll('[role="option"]', listbox).filter(
      (o) => o.getAttribute('aria-disabled') !== 'true',
    );

    if (nonDisableds.length) {
      listbox.setAttribute('aria-activedescendant', nonDisableds[0].id);
    }
  }

  listbox.focus();
  listbox.setAttribute('data-cached-selected', listbox.getAttribute('aria-activedescendant'));
  listbox.setAttribute('aria-hidden', false);
  activate(listbox);
};

export default open;
