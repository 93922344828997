/**
 * Cleans up previously selected / sets new selection
 */
const select = (listboxButton, listbox, noHide = false) => {
  // clear selected state
  const prevSelected = listbox.querySelector(`#${listbox.dataset.cachedSelected}`);
  if (prevSelected) {
    prevSelected.removeAttribute('aria-selected');
    prevSelected.classList.remove('customSelect--selected');
  }

  const active = listbox.querySelector(`#${listbox.getAttribute('aria-activedescendant')}`);

  if (active) {
    active.setAttribute('aria-selected', 'true');
    active.classList.add('customSelect--selected');

    // fire a custom change event
    const onChange = new CustomEvent('change', {
      bubbles: true,
      detail: {
        value: active.dataset.value,
      },
    });

    listboxButton.dispatchEvent(onChange);
  }

  if (!noHide) {
    // hide the list
    listbox.classList.remove('customSelect--listShow');
    listbox.setAttribute('aria-hidden', true);
    listboxButton.setAttribute('aria-expanded', 'false');
    listboxButton.focus();
  }

  // set pseudoVal
  const pseudoVal = active && listboxButton.querySelector('.customSelect-fieldValue');
  if (pseudoVal) {
    pseudoVal.textContent = active.dataset.label;
  }
};

export default select;
