import queryAll from '../query-all';

/**
 * Activates an option
 */
const activate = (listbox) => {
  // clean
  queryAll('[role="option"].customSelect--selected', listbox).forEach((o) => {
    o.classList.remove('customSelect--selected');
  });

  queryAll('[aria-selected="true"]', listbox).forEach((o) => o.removeAttribute('aria-selected'));

  const optionID = listbox.getAttribute('aria-activedescendant');
  const active = optionID && document.getElementById(optionID);

  if (!active) {
    return;
  }

  active.classList.add('customSelect--selected');
  active.setAttribute('aria-selected', 'true');
};

export default activate;
