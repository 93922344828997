const { isObject } = window.lodash;

const objectToQueryString = (object) =>
  Object.keys(object)
    .map((prop) => {
      let val = object[prop];

      if (Array.isArray(val)) {
        val = val.join(',');
      }

      if (isObject(val)) {
        val = objectToQueryString(object);
      }

      return `${encodeURIComponent(prop)}=${encodeURIComponent(val)}`;
    })
    .join('&');

export default objectToQueryString;
